<template>
  <div class="loading">
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
    <div class="ball"></div>
  </div>
</template>

<style scoped>
.loading {
  background-color: var(--dark-white);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.ball {
  height: 18px;
  width: 18px;
  background: var(--light-green-pecsmart);
  border-radius: 50%;
  margin: 12px;
  animation: inflate 1.2s infinite;
}

.ball:nth-child(1) {
  animation-delay: 0;
}
.ball:nth-child(2) {
  animation-delay: 0.2s;
}
.ball:nth-child(3) {
  animation-delay: 0.4s;
}
.ball:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes inflate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
